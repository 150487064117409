import {useEffect} from "react";

interface ICaptchaProps {
    callback: (status: string) => any,
    siteKey: string,
}

declare global {
    interface Window {
        recaptchaSuccessCallback: (d: any) => void,
        recaptchaExpiredCallback: (d: any) => void,
        recaptchaErrorCallback: (d: any) => void,

    }
}

function Captcha({siteKey, callback}: ICaptchaProps) {

    const recaptchaSuccessCallback = (d: any) => {
        console.log("recaptcha success", d)
        callback(d);
    }

    const recaptchaExpiredCallback = (d: any) => {
        console.log("recaptcha expired", d)
        callback("");
    }

    const recaptchaErrorCallback = (d: any) => {
        console.log("recaptcha error", d)
        callback("");
    }

    useEffect(() => {
        let scriptUrl = "https://www.google.com/recaptcha/api.js";
        let scriptTag = document.createElement("script");
        scriptTag.async = true;
        scriptTag.src = scriptUrl;
        scriptTag.type = "text/javascript";
        document.body.appendChild(scriptTag);

        window.recaptchaSuccessCallback = recaptchaSuccessCallback;
        window.recaptchaExpiredCallback = recaptchaExpiredCallback;
        window.recaptchaErrorCallback = recaptchaErrorCallback;
    })

    return <>
        <div
            className={"g-recaptcha"}
            data-sitekey={siteKey}
            data-theme={"dark"}
            data-callback={"recaptchaSuccessCallback"}
            data-expired-callback={"recaptchaExpiredCallback"}
            data-error-callback={"recaptchaErrorCallback"}
        ></div>
    </>
}

export default Captcha;
