const scrollToTarget = (id: string) => {
  const newPos = document.getElementById(id)?.offsetTop;
  if(!newPos) return;

  const currentPos = window.scrollY;
  window.scrollBy({
    top: newPos - currentPos,
    left: 0,
    behavior: 'smooth',
  });

  window.history.pushState({}, "", `#${id}`)
}

function Hero() {
    return (
      <div className="relative isolate overflow-hidden bg-black-blue">
        <div className="mx-auto max-w-7xl px-6 flex flex-col-reverse lg:flex lg:flex-row lg:items-center">
          <div className="mx-auto -mt-32 max-w-2xl z-20 flex-shrink-0 lg:mx-0 lg:max-w-xl lg:pt-8 lg:mt-0">
            <img
              className="h-11"
              src="RSports-logo.png"
              alt="RSports Interactive"
            />
            <h1 className="mt-10 text-4xl font-bold tracking-tight text-white sm:text-5xl">
              Experts in Fantasy Sports
            </h1>
            <p className="mt-6 text-lg leading-8 text-gray-300">
              Our mission is to be the market leader in the world for the delivery of free and cash-based interactive games for sports fans.
            </p>
            <div className="mt-10 flex items-center gap-x-6">
              <button
                type="button"
                onClick={() => scrollToTarget("contact")}
                className="bg-light-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
              >
                Contact Us
              </button>
            </div>
          </div>
          <div className="flex justify-end z-10 lg:ml-10 lg:mr-0 lg:mt-0 lg:max-w-none lg:flex-none xl:ml-32">
            <div className="max-w-3xl flex-none sm:max-w-5xl lg:max-w-none">
              <img
                src="hero-fb-image.png"
                alt="App screenshot"
                width={866}
                height={755}
                className="w-[24rem] lg:w-[42rem]"
              />
            </div>
          </div>
        </div>
      </div>
    );
}

export default Hero;
