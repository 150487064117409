import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import {createBrowserRouter, RouterProvider} from "react-router-dom";
import Home from "./Pages/Home";
import TagManager from 'react-gtm-module';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Home />,
        children: [
        ],
    },
]);


let okToLoad = false;
if(window.location.host === "rsports.games") {
  window.location.href = "https://www.rsports.games"
}else{
  okToLoad = true;
}

if(okToLoad){
  const tagManagerArgs = {
    gtmId: 'GTM-PK8MBT92',
  }

  TagManager.initialize(tagManagerArgs)

  const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
  );
  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>
  );
}
