import Captcha from "./Captcha";
import {useState} from "react";
import {useForm} from "react-hook-form";

const FooterForm = () => {

    const devMode = process.env.NODE_ENV === "development";

    const formEnabled = () => captchaStatus !== "";

    const [captchaStatus, setCaptchaStatus] = useState<string>("");
    const [submitted, setSubmitted] = useState<boolean>(false);
    const [formSubmitError, setFormSubmitError] = useState<string>("");

    const submit = (data: any) => {
        const submitUrl = devMode ? "http://localhost:8080/contact/rsports" : "https://www.rsports.games/contact/rsports"
        data["captchaToken"] = captchaStatus;

        fetch(submitUrl, {
            method: "POST",
            body: JSON.stringify(data),
            headers: {
                "content-type": "application/json",
            }
        }).then((response)=> {
            if(response.ok){
                setSubmitted(true);
            }else{
                setFormSubmitError("Bad response from server.")
            }
        }).catch((error)=>{
            setFormSubmitError("Unknown error.");
        });
    }

    const {
        register,
        handleSubmit,
    } = useForm({});

    return <>
        {submitted ?
        <>
            <div className="px-6 pb-24 pt-20 sm:pb-32 lg:px-8 lg:py-48 text-white">Thank you!  A member of our team will respond to your message soon.</div>
        </>
        :
        <form action="#" method="POST" className="mx-auto mt-12 max-w-2xl sm:mt-12" onSubmit={handleSubmit(submit)}>
            {devMode && <div className={"p-5 bg-red-900 border-2 border-white text-center text-white"}>Development Mode</div>}
            <div className="grid grid-cols-1 gap-x-8 gap-y-6 sm:grid-cols-2">
                    <div>
                        <label htmlFor="name" className="block text-base leading-6 text-white">
                            Name
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="text"
                                autoComplete="given-name"
                                className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                {...register("name", {required: true })}
                            />
                        </div>
                    </div>
                    <div>
                        <label htmlFor="phone-number" className="block text-base leading-6 text-white">
                            Phone number
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="tel"
                                id="phone-number"
                                autoComplete="tel"
                                className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                {...register("phoneNumber", {required: true })}
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="email" className="block text-base leading-6 text-white">
                            Email
                        </label>
                        <div className="mt-2.5">
                            <input
                                type="email"
                                id="email"
                                autoComplete="email"
                                className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                                {...register("email", {required: true })}
                            />
                        </div>
                    </div>
                    <div className="sm:col-span-2">
                        <label htmlFor="message" className="block text-base leading-6 text-white">
                            Message
                        </label>
                        <div className="mt-2.5">
                <textarea
                    id="message"
                    rows={4}
                    className="block w-full rounded-md border-0 bg-white/5 px-3.5 py-2 text-white shadow-sm ring-1 ring-inset ring-white/10 focus:ring-2 focus:ring-inset focus:ring-red-500 sm:text-sm sm:leading-6"
                    maxLength={500}
                    {...register("message", {required: true, maxLength: 500 })}
                />
                        </div>
                    </div>
                <div className="mt-8 xs:flex xs:justify-center">
                    <Captcha callback={setCaptchaStatus} siteKey={"6LedVXQpAAAAAE5g-0NauACqdL8Nxca5Q6xRP6dV"}></Captcha>
                </div>
                <div className="mt-8 flex flex-initial justify-end align-center pt-4 pb-4 xs:justify-center">
                    <button
                        type="submit"
                        className={formEnabled() ?
                            "bg-light-blue px-3.5 py-2.5 text-center text-base text-white shadow-sm hover:bg-red-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-500 disabled:opacity-75" :
                            "bg-light-blue px-3.5 py-2.5 text-center text-base text-gray-300 shadow-sm hover:bg-red-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-red-900 disabled:opacity-75"}
                        disabled={!formEnabled()}
                    >
                        Send message
                    </button>
                </div>
                {formSubmitError && <>
                    <div className="p-5 text-white">There was an error submitting your message: {formSubmitError}<br /><br />Please try again.
                    </div>
                </>}
            </div>
        </form>}
    </>
}

export default FooterForm;
