import FooterForm from "./FooterForm";

function Footer() {

    return (
      <div className="isolate bg-black-blue px-6 pb-24 sm:pb-32 lg:px-8" id={"contact"}>
        <div className="mx-auto max-w-2xl lg:text-center" id={"contact"}>
          <h2 className="text-4xl font-bold tracking-tight text-white font-semibold">Get in touch</h2>
          <p className="mt-2 text-lg leading-8 text-white">
            Have questions? Interested in creating something fun?
          </p>
        </div>
        <FooterForm></FooterForm>
      </div>
  );
}

export default Footer;
