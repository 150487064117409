function Services() {
    return (
      <div className="overflow-hidden bg-black-blue py-24 sm:py-32">
        <div className="mx-auto max-w-7xl px-6 lg:px-8">
          <div className="mx-auto grid max-w-2xl grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:mx-0 lg:max-w-none lg:grid-cols-2">
            <div className="">
              <div className="lg:max-w-lg">
                <h2 className="text-lg font-semibold leading-7 text-sky-500">Free-to-Play</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">Sports Technologies</p>
                <p className="mt-6 text-lg leading-8 text-gray-300 pb-4">
                  The market leader for nearly 20 years in the delivery and management of free-to-play fantasy games.
                </p>
                <img
                  src="sports-tech.png"
                  alt="Product screenshot"
                  className="w-[29rem] max-w-none sm:w-[29rem] mb-8"
                  width={595}
                  height={296}
                />
                <a
                  href="https://www.sportstechinc.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-light-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
                >
                  Learn More
                </a>
              </div>
            </div>
            <div className="">
              <div className="lg:max-w-lg">
                <h2 className="text-lg font-semibold leading-7 text-sky-500">Pay-to-Play</h2>
                <p className="mt-2 text-3xl font-bold tracking-tight text-white sm:text-4xl">SportsHub Games Network</p>
                <p className="mt-6 text-lg leading-8 text-gray-300 pb-4">
                  The SportsHub Games Network delivers a wide variety of owned and operated Fantasy Gaming products.
                </p>
                <img
                  src="sportshub.png"
                  alt="Product screenshot"
                  className="w-[26rem] max-w-none sm:w-[26rem] mb-8"
                  width={450}
                  height={270}
                />
                <a
                  href="https://www.shgn.com/"
                  target="_blank"
                  rel="noreferrer"
                  className="bg-light-blue px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-sky-700 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-700"
                >
                  Learn More
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
}

export default Services;
