import Hero from "../Components/Hero";
import Services from "../Components/Services";
import Footer from "../Components/Footer";

function Home() {
    return (
        <>
            <Hero></Hero>
            <Services></Services>
            <Footer></Footer>
        </>
    );
}

export default Home;
